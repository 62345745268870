export const mkHash = async str => {
	if(!str)
		return null

	const encoder = new TextEncoder()
	const data = encoder.encode(str)
	const hashBuffer = await crypto.subtle.digest("SHA-256", data)

	return Array.from(new Uint8Array(hashBuffer))
		.map(byte => byte.toString(16).padStart(2, "0"))
		.join("")
}
